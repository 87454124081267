import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Layout, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import Accept from "app/components/icons/Accept";
import Deny from "app/components/icons/Deny";
import ProfileDetailsComponent from "app/components/pages/PersonalDetails";
import ProfileComponent from "app/components/pages/ProfileDetail";
import Scroll from "app/components/scroll/Scroll";
import { darkGrey, white } from "app/styles/theme";
import { fetchSubscription, HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const ProfileDetail: React.FC<any> = (props) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [userMatches, setUserMatches]: any = React.useState({});

  const fetchUserMatches = async () => {
    try {
      const id =
        props?.route?.params?.user_match_id ||
        window?.location?.search?.split("=")[1];
      const token = await AsyncStorage.getItem("token");
      const response = await axios.get(`${HOSTNAME}/api/v1/user_matchs/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response?.data;
      setUserMatches(Array.isArray(data?.data) ? data?.data[0] : data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const setUserAccepted = async (accepted: boolean) => {
    try {
      const id = props?.route?.params?.user_match_id;
      const userId = await AsyncStorage.getItem("userId");
      const token = await AsyncStorage.getItem("token");
      const key =
        Number(userMatches?.user_id) === Number(userId)
          ? "user_accepted"
          : "match_accepted";
      const response = await axios.put(
        `${HOSTNAME}/api/v1/user_matchs/${id}`,
        { [key]: accepted },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
    } catch (error) {
      console.error(error);
    }
  };

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     props.navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, props?.route?.name]);

  React.useEffect(() => {
    fetchUserMatches();
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Scroll>
        <View style={styles.row}>
          <View></View>
          <Text category="h1">Profile Detail</Text>
          <View></View>
        </View>
        <ProfileComponent {...props} />
        <ProfileDetailsComponent {...props} />
        <View style={styles.row}>
          <TouchableOpacity
            onPress={async () => {
              await setUserAccepted(true);
            }}
          >
            <Accept fill={"green"} style={styles.acceptIcon} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={async () => {
              await setUserAccepted(false);
            }}
          >
            <Deny fill={"red"} style={styles.denyIcon} />
          </TouchableOpacity>
        </View>
      </Scroll>
    </Layout>
  );
};

const styles = StyleSheet.create({
  acceptIcon: {
    color: "green",
    height: 32,
    width: 32,
  },
  container: {
    alignItems: "center",

    flex: 1,
    justifyContent: "center",
  },
  denyIcon: {
    color: "red",
    height: 32,
    width: 32,
  },
  row: {
    alignContent: "center",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
});

export default ProfileDetail;
