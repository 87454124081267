/* eslint-disable */
import * as React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Image,
  Pressable,
} from "react-native";
import {
  MediaStream,
  mediaDevices,
  RTCPeerConnection,
  RTCView,
  RTCIceCandidate,
  RTCSessionDescription,
} from "react-native-webrtc-web-shim";
import CallEnd from "../icons/CallEnd";
import CameraSwitch from "../icons/CameraSwitch";
import MicOff from "../icons/MicOff";
import MicOn from "../icons/MicOn";
import VideoOff from "../icons/VideoOff";
import VideoOn from "../icons/VideoOn";
import IconContainer from "./IconContainer";
import { Layout, Modal } from "@ui-kitten/components";
import ConnectFour from "../games/versus/ConnectFour";
import HamburgerMenu from "../icons/HamburgerMenu";
import { get } from "axios";

const LIST_OF_GAMES = [
  {
    name: "Connect Four",
    component: ConnectFour,
    image: require("../../../assets/images/connect-four.png"),
  },
  {
    name: "Quit Game",
    component: null,
    image: require("../../../assets/images/X.png"),
  },
];

const AudioCall = ({
  socket,
  virtual,
  setMatch,
  route,
  callerId,
  callerOtherId,
}: any) => {
  const PC_CONFIG = {};
  let peers: any = {};
  let pendingCandidates: any = {};

  const [localStream, setLocalStream]: any = React.useState(null);
  const [remoteStream, setRemoteStream]: any = React.useState(null);
  const [type, setType]: any = React.useState("WEBRTC_ROOM");
  const [localMicOn, setlocalMicOn]: any = React.useState(true);
  const [localWebcamOn, setlocalWebcamOn]: any = React.useState(true);
  const [menu, setMenu]: any = React.useState(false);
  const [currentGame, setCurrentGame]: any = React.useState(null);

  function switchCamera() {
    localStream?.getVideoTracks().forEach((track: any) => {
      track._switchCamera();
    });
  }

  function toggleCamera(boolean?: boolean) {
    localWebcamOn
      ? setlocalWebcamOn(boolean || false)
      : setlocalWebcamOn(boolean || true);
    localStream?.getVideoTracks().forEach((track: any) => {
      !localWebcamOn || boolean
        ? (track.enabled = true)
        : (track.enabled = false);
    });
  }

  function toggleMic(boolean?: boolean) {
    localMicOn
      ? setlocalMicOn(boolean || false)
      : setlocalMicOn(boolean || true);
    localStream?.getAudioTracks().forEach((track: any) => {
      !localMicOn || boolean ? (track.enabled = true) : (track.enabled = false);
    });
  }

  function leave() {
    toggleMic(false);
    toggleCamera(false);
    setLocalStream(null);
    setRemoteStream(null);
    setType("MATCH");
  }

  const renderGame = () => {
    const Component = currentGame?.component;
    if (!Component) return null;
    return <Component date={virtual} socket={socket} route={route} />;
  };

  const getRoomId = () => {
    return Number(callerId) > Number(callerOtherId)
      ? `${callerId}-${callerOtherId}`
      : `${callerOtherId}-${callerId}`;
  };

  React.useEffect(() => () => leave(), []);

  React.useEffect(() => {
    const sendData = (data: any) => {
      console.log("Sending " + data.type + " data: ", data);
      data.roomId = getRoomId();
      socket.emit("data", data);
    };

    const getLocalStream = async () => {
      try {
        const stream: MediaStream = await mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        setLocalStream(stream);
        return stream;
      } catch (error) {
        console.error("Stream not found: ", error);
      }
    };

    const createPeerConnection = (ls?: MediaStream) => {
      const pc: any = new RTCPeerConnection(PC_CONFIG);
      pc.onicecandidate = onIceCandidate;
      pc.onaddstream = onAddStream;
      const local = ls || localStream;
      if (local) pc.addStream(local);
      return pc;
    };

    const sendOffer = async (sid: any = getRoomId()) => {
      try {
        const sdp = await peers[sid].createOffer();
        return await setAndSendLocalDescription(sid, sdp);
      } catch (error) {
        console.error(error);
      }
    };

    const sendAnswer = async (sid: any = getRoomId()) => {
      try {
        const sdp = await peers[sid].createAnswer();
        return await setAndSendLocalDescription(sid, sdp);
      } catch (error) {
        console.error(error);
      }
    };

    const setAndSendLocalDescription = async (
      sid: any = Number(callerId) > Number(callerOtherId)
        ? `${callerId}-${callerOtherId}`
        : `${callerOtherId}-${callerId}`,
      sessionDescription: any
    ) => {
      try {
        await peers[sid].setLocalDescription(sessionDescription);
        sendData({
          sid,
          type: sessionDescription.type,
          sdp: sessionDescription.sdp,
        });
      } catch (error) {
        console.error(error);
      }
    };

    const onIceCandidate = (event: any) => {
      if (event.candidate) {
        sendData({
          type: "candidate",
          candidate: event.candidate,
          sid: socket.id,
        });
      }
    };

    const onAddStream = (event: any) => {
      try {
        setRemoteStream(event?.stream);
      } catch (error) {
        console.error(error);
      }
    };

    const addPendingCandidates = async (sid: any = getRoomId()) => {
      try {
        if (sid in pendingCandidates) {
          return await Promise.all(
            pendingCandidates[sid].map((candidate: any) =>
              peers[sid].addIceCandidate(new RTCIceCandidate(candidate))
            )
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleSignalingData = async (data: any) => {
      try {
        data.roomId = getRoomId();
        data.sid = data.roomId;
        const sid = data.sid;
        if (data.type === "gameSelected") {
          if (data.game === "Quit Game") {
            setCurrentGame(null);
          } else {
            const game = LIST_OF_GAMES.find((game) => game.name === data.game);
            setCurrentGame(game);
          }
        } else if (data.type === "offer") {
          peers[sid] = createPeerConnection();
          console.log("Offer received: ", data);
          peers[sid].setRemoteDescription(
            new RTCSessionDescription({
              type: data.type,
              sdp: data.sdp,
            })
          );
          await sendAnswer(sid);
          await addPendingCandidates(sid);
        } else if (data.type === "answer") {
          console.log("Answer received: ", data);
          peers[sid].setRemoteDescription(
            new RTCSessionDescription({
              type: data.type,
              sdp: data.sdp,
            })
          );
        } else if (data.type === "candidate") {
          if (sid in peers) {
            peers[sid].addIceCandidate(new RTCIceCandidate(data.candidate));
          } else {
            if (!(sid in pendingCandidates)) {
              pendingCandidates[sid] = [];
            }
            pendingCandidates[sid].push(data.candidate);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    socket.on("connect", async () => {
      try {
        const sid = getRoomId();
        const stream = await getLocalStream();
        peers[sid] = createPeerConnection(stream);
        await sendOffer(sid);
        await addPendingCandidates(sid);
      } catch (error) {
        console.error(error);
      }
    });

    socket.on("data", async (data: any) => {
      try {
        data.roomId = getRoomId();
        data.sid = data.roomId;
        await handleSignalingData(data);
      } catch (error) {
        console.error(error);
      }
    });

    socket.on("connect_error", (err: any) => {
      console.error(`connect_error due to ${err.message}`);
    });

    return () => {
      socket.off("connect");
      socket.off("connect_error");
      socket.off("data");
      socket.off("disconnect");
    };
  }, []);

  const MatchScreen = () => {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "#050A0E",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Text
          style={{
            fontSize: 36,
            color: "#ffff",
          }}
        >
          Open messaging?
        </Text>
        <View
          style={{
            flexDirection: "row",
            marginTop: 12,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setType("MATCH");
              setMatch(true);
            }}
            style={{
              height: 50,
              backgroundColor: "#5568FE",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 12,
              marginRight: 8,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
              }}
            >
              Yes
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setType("MATCH");
              setMatch(false);
            }}
            style={{
              height: 50,
              backgroundColor: "#FF5D5D",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 12,
              marginLeft: 8,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                color: "#FFFFFF",
              }}
            >
              No
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  const WebrtcRoomScreen = () => {
    return (
      <Layout
        style={{
          backgroundColor: "#050A0E",
          height: Dimensions.get("window").height,
          width: Dimensions.get("window").width,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          {renderGame()}
          {!!currentGame?.component ? (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-evenly",
                height: 200,
              }}
            >
              <RTCView
                style={{
                  backgroundColor: "#050A0E",
                  width: "50%",
                  height: "50%",
                  position: "absolute",
                }}
                stream={localStream}
              />
              <RTCView
                style={{
                  backgroundColor: "#050A0E",
                  width: "100%",
                  height: "100%",
                }}
                stream={remoteStream}
              />
            </View>
          ) : (
            <>
              <RTCView
                style={{
                  backgroundColor: "#050A0E",
                  width: "50%",
                  height: "50%",
                  position: "absolute",
                }}
                stream={localStream}
              />
              <RTCView
                style={{
                  backgroundColor: "#050A0E",
                  width: "100%",
                  height: "100%",
                }}
                stream={remoteStream}
              />
            </>
          )}
          <View
            style={{
              width: "100%",
              height: 200,
              zIndex: 100,
              position: "absolute",
              bottom: 0,
            }}
          >
            <View
              style={{
                maxHeight: 200,
                marginVertical: 12,
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <IconContainer
                backgroundColor={"red"}
                onPress={() => {
                  leave();
                  setType("MATCH");
                }}
                Icon={() => {
                  return <CallEnd height={26} width={26} fill="#FFF" />;
                }}
              />
              <IconContainer
                style={{
                  borderWidth: 1.5,
                  borderColor: "#2B3034",
                }}
                backgroundColor={!localMicOn ? "#fff" : "transparent"}
                onPress={() => {
                  toggleMic();
                }}
                Icon={() => {
                  return localMicOn ? (
                    <MicOn height={24} width={24} fill="#FFF" />
                  ) : (
                    <MicOff height={28} width={28} fill="#1D2939" />
                  );
                }}
              />
              <IconContainer
                style={{
                  borderWidth: 1.5,
                  borderColor: "#2B3034",
                }}
                backgroundColor={!localWebcamOn ? "#fff" : "transparent"}
                onPress={() => {
                  toggleCamera();
                }}
                Icon={() => {
                  return localWebcamOn ? (
                    <VideoOn height={24} width={24} fill="#FFF" />
                  ) : (
                    <VideoOff height={36} width={36} fill="#1D2939" />
                  );
                }}
              />
              <IconContainer
                style={{
                  borderWidth: 1.5,
                  borderColor: "#2B3034",
                }}
                backgroundColor={"transparent"}
                onPress={() => {
                  switchCamera();
                }}
                Icon={() => {
                  return <CameraSwitch height={24} width={24} fill="#FFF" />;
                }}
              />
              <IconContainer
                style={{
                  borderWidth: 1.5,
                  borderColor: "#2B3034",
                }}
                backgroundColor={"transparent"}
                onPress={() => {
                  setMenu(!menu);
                }}
                Icon={() => {
                  return <HamburgerMenu height={24} width={24} fill="#FFF" />;
                }}
              />
            </View>
          </View>
        </View>
        <Modal
          visible={menu}
          backdropStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          onBackdropPress={() => setMenu(false)}
        >
          <View>
            <View>
              <Text>Menu</Text>
            </View>
            <View>
              {LIST_OF_GAMES.map((gameObj, key) => (
                <Pressable
                  key={key}
                  onPress={() => {
                    setCurrentGame(gameObj);
                    socket.emit("data", {
                      type: "gameSelected",
                      game: gameObj.name,
                    });
                    setMenu(false);
                  }}
                >
                  <View>
                    <Image
                      style={{ height: 50, width: 50 }}
                      source={gameObj.image}
                    />
                    <Text style={{ color: "white" }}>{gameObj.name}</Text>
                  </View>
                </Pressable>
              ))}
            </View>
          </View>
        </Modal>
      </Layout>
    );
  };

  switch (type) {
    case "WEBRTC_ROOM":
      return WebrtcRoomScreen();
    case "MATCH":
      return MatchScreen();
    default:
      return null;
  }
};

export default AudioCall;
