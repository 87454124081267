import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import Age from "app/components/pages/Age";
import { darkGrey, white } from "app/styles/theme";
// import { fetchSubscription } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const AgeSetup = (props: any) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     props.navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, props?.route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <Age {...props} />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    height: "100%",
    width: "100%",
  },
});

export default AgeSetup;
