import * as React from "react";

import * as eva from "@eva-design/eva";
import * as Sentry from "@sentry/browser";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { ApplicationProvider } from "@ui-kitten/components";
import * as evaIcons from "@ui-kitten/eva-icons";
import axios from "axios";
import * as Font from "expo-font";
import { StyleSheet, Text, View, ImageBackground } from "react-native";
import { AutocompleteDropdownContextProvider } from "react-native-autocomplete-dropdown";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import {
  PaperProvider,
  MD3LightTheme as LightTheme,
  MD3DarkTheme as DarkTheme,
} from "react-native-paper";

import { linkConfig, screenConfig } from "app/config/screens";
import { myTheme as theme } from "app/styles/custom-theme";
import { HOSTNAME, redirectToHomepageWhenExpired } from "app/utils/ajax";
import { AuthContext, ThemeContext } from "app/utils/context";
import cors from "app/config/cors.json";
import packageJSON from "package.json";

const App: React.FC<any> = (props) => {
  const isProd = process.env.NODE_ENV === "production";

  const [token, setToken] = React.useState("");
  const [route, setRoute] = React.useState("");
  const [themes, setThemes] = React.useState("light");

  const navigation = React.useRef();

  const appContextValue = React.useMemo(
    () => ({ route, setRoute, token, setToken }),
    [route, setRoute]
  );

  const themeContextValue = React.useMemo(
    () => ({ themes, setThemes }),
    [themes, setThemes]
  );

  const fetchFonts = async (): Promise<void> => {
    await Font.loadAsync({
      Helvetica: require("../assets/fonts/Helvetica/Helvetica.ttf"),
      "Helvetica-Bold": require("../assets/fonts/Helvetica/Helvetica-Bold.ttf"),
      Futura: require("../assets/fonts/Futura/FuturaPTHeavy.otf"),
    });
  };

  const Stack = createStackNavigator();

  Sentry.init({
    dsn: "https://ecd6223aeac5ee0a5fdf14617ba3b2ed@o4507245944569856.ingest.us.sentry.io/4507245958397952",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [...cors, "localhost"],
    release: `${packageJSON.name}@${packageJSON.version}`,
  });

  React.useLayoutEffect(() => {
    fetchFonts().catch(console.error);
  }, []);

  React.useLayoutEffect(() => {
    const checkToken = async (): Promise<void> => {
      try {
        const jwt = await AsyncStorage.getItem("token");
        if (!jwt) throw new Error("Token expired");

        const headers = { Authorization: `Bearer ${jwt}` };
        const res = await axios.get(`${HOSTNAME}/api/v1/me`, {
          headers,
        });
        if (res?.data?.error) {
          throw new Error(res?.data);
        }
        setToken(jwt || "");
        await AsyncStorage.multiSet([
          ["userId", String(res?.data?.id) || ""],
          ["phoneNumber", res?.data?.phone_number || ""],
          ["isActive", String(res?.data?.is_active) || ""],
          ["token", jwt || ""],
          ["deletedAt", res?.data?.deleted_at || ""],
          ["subscription", res?.data?.subscription_id || ""],
        ]);
      } catch (error: any) {
        await AsyncStorage.multiRemove([
          "userId",
          "token",
          "phoneNumber",
          "isActive",
          "deletedAt",
          "subscription",
        ]);
        setToken("");
        setRoute("Login");
        props?.navigation?.navigate("Login", { message: "Token expired" });
        await redirectToHomepageWhenExpired(error, props.navigation, setRoute);
      }
    };

    checkToken().catch(console.error);
  }, [route, props.navigation]);

  let themeObject = { ...theme };
  React.useEffect(() => {
    const theming = themes === "light" ? eva.light : eva.dark;
    themeObject = { ...theming, ...theme };
  }, [themes]);

  if (isProd) {
    return (
      <ImageBackground
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
        source={require("../assets/images/cross.jpg")}
      >
        <Text
          style={{
            color: "white",
            position: "absolute",
            top: 50,
            fontSize: 36,
          }}
        >
          Under Construction
        </Text>
      </ImageBackground>
    );
  }

  return (
    <AuthContext.Provider value={appContextValue}>
      <ThemeContext.Provider value={themeContextValue}>
        <ApplicationProvider {...eva} {...evaIcons} theme={themeObject}>
          <PaperProvider theme={themes === "light" ? LightTheme : DarkTheme}>
            <GestureHandlerRootView style={styles.container}>
              <AutocompleteDropdownContextProvider>
                <NavigationContainer
                  ref={navigation.current}
                  linking={linkConfig}
                  independent={true}
                >
                  <Stack.Navigator>
                    {token
                      ? screenConfig.map((screen: any, index: number) => (
                          <Stack.Screen
                            key={index}
                            {...screen}
                            themes={themes}
                            options={(p) =>
                              screen.options({ ...p, ...props, themes })
                            }
                          />
                        ))
                      : screenConfig
                          .filter((screen: any) => !token && !screen.token)
                          .map((screen: any, index: number) => (
                            <Stack.Screen
                              key={index}
                              {...screen}
                              themes={themes}
                              options={(p: any) =>
                                screen.options({
                                  ...p,
                                  ...props,
                                  themes,
                                })
                              }
                            />
                          ))}
                  </Stack.Navigator>
                </NavigationContainer>
              </AutocompleteDropdownContextProvider>
            </GestureHandlerRootView>
          </PaperProvider>
        </ApplicationProvider>
      </ThemeContext.Provider>
    </AuthContext.Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default App;
