import * as React from "react";

import { Layout } from "@ui-kitten/components";
import { StyleSheet } from "react-native";

import AudioCall from "app/components/audio/AudioCall";
// import Scroll from "app/components/scroll/Scroll";
import { darkGrey, white } from "app/styles/theme";
import { getSocket, HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import moment from "moment";

const VirtualDate: React.FC<any> = (props) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [virtual, setVirtual] = React.useState({});
  const [callerId, setCallerId] = React.useState(
    props?.route?.params?.userCallerId
  );
  const [callerOtherId, setOtherCallerId] = React.useState(
    props?.route?.params?.matchCallerId
  );

  const fetchVirtualDate = async () => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/virtual_date?user_caller_id=${props?.route?.params?.userCallerId}&match_caller_id=${props?.route?.params?.matchCallerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const date = data?.data[0];
      setVirtual(date);
      setCallerId(
        Number(date?.user_id) === Number(userId)
          ? String(date?.user_caller_id)
          : String(date?.match_caller_id)
      );
      setOtherCallerId(
        Number(date?.user_id) === Number(userId)
          ? String(date?.match_caller_id)
          : String(date?.user_caller_id)
      );
      await AsyncStorage.setItem("callerId", String(callerId));
    } catch (error) {
      console.error(error);
    }
  };

  const setMatch = async (accepted: boolean) => {
    try {
      const myCallerId = await AsyncStorage.getItem("callerId");
      const token = await AsyncStorage.getItem("token");
      const response = await axios.put(
        `${HOSTNAME}/api/v1/virtual_dates`,
        {
          user_caller_id: callerId,
          match_caller_id: callerOtherId,
          datestring: moment().format(),
          [myCallerId === callerId ? "user_accepted" : "match_accepted"]:
            accepted,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = response?.data;
      setVirtual(data);
    } catch (error) {
      console.error(error);
    }
  };

  const socket = getSocket(callerId, callerOtherId);

  React.useLayoutEffect(() => {
    setRoute(props?.route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     props?.navigation.navigate("Membership Setup");
    //   }
    // });
    fetchVirtualDate();

    const setCallerIds = async () => {
      const userCallerId = props?.route?.params?.userCallerId;
      const matchCallerId = props?.route?.params?.matchCallerId;

      setCallerId(String(userCallerId));
      setOtherCallerId(String(matchCallerId));
    };

    setCallerIds();
  }, [setRoute, props?.route?.name, props?.navigation]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <AudioCall
        {...props}
        {...{
          socket,
          callerId,
          callerOtherId,
          setCallerId,
          setOtherCallerId,
          fetchVirtualDate,
          setMatch,
          virtual,
          setVirtual,
        }}
      />
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
});

export default VirtualDate;
