import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Card, Input, Layout, Text } from "@ui-kitten/components";
import axios from "axios";
import { StyleSheet, Pressable, useWindowDimensions, View } from "react-native";
import { Button } from "react-native-paper";
import SocketIOClient from "socket.io-client";

import Loader from "app/components/loaders/Spinner";
import {
  black,
  darkGrey,
  lightGrey,
  white,
  primary,
  maxWidth,
} from "app/styles/theme";
import {
  fetchSubscription,
  HOSTNAME,
  WEBSOCKETS_HOSTNAME,
} from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import Options from "app/components/popups/Options";

const Chat: React.FC<any> = ({ navigation, route }) => {
  const layout = useWindowDimensions();
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [messages, setMessages]: any = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [userId, setUserId] = React.useState(0);
  const [text, setText] = React.useState("");

  const socket: any = SocketIOClient(WEBSOCKETS_HOSTNAME, {
    transports: ["websocket"],
    query: {
      id: route?.params?.id,
    },
  });

  const loadUser = async () => {
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const id = route?.params?.id;
      const token = await AsyncStorage.getItem("token");

      const { data } = await axios.get(
        `${HOSTNAME}/api/v1/message_threads/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setMessages(data?.data || []);
      setLoading(false);
      return data?.data || [];
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const sendMessage = async (message: string) => {
    try {
      setLoading(true);
      const id = route?.params?.id;
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.post(
        `${HOSTNAME}/api/v1/message_threads/${id}`,
        { message: message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const allMessages = [
        ...(Array.isArray(messages) ? messages : []),
        data?.data || { message },
      ];
      setMessages(allMessages);
      socket?.emit("sendMessage", {
        message: data?.data?.message,
        userId: data?.data?.user_id,
        id: data?.data?.id,
      });
      setText("");
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
  }, [setRoute, route?.name]);

  React.useEffect(() => {
    loadUser().catch(console.error);
  }, [userId]);

  React.useEffect(() => {
    fetchMessages().catch(console.error);
  }, []);

  React.useEffect(() => {
    socket?.on("receiveMessage", (data: any) => {
      fetchMessages()
        .then((mess: any) => setMessages([...mess]))
        .catch(console.error);
    });

    return () => {
      socket?.disconnect();
    };
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <View style={styles.row}>
        <View>
          <Pressable onPress={fetchMessages}>
            <Text style={styles.icon}>⟳</Text>
          </Pressable>
        </View>
        <Text category="h1">Chat</Text>
        <View>
          <Options
            navigation={navigation}
            route={route}
            matchId={route?.params?.matchId}
          />
        </View>
      </View>
      <Card style={styles.layout}>
        {Array.isArray(messages) &&
          messages
            ?.filter((e) => e.message)
            ?.sort((a, b) => a.id - b.id)
            ?.map((message: any, index: number) => (
              <View style={Object.assign({}, styles.chatContainer)} key={index}>
                <View
                  style={Object.assign({}, styles.bubbleContainer, {
                    justifyContent:
                      message?.user_id !== Number(userId)
                        ? "flex-start"
                        : "flex-end",
                    maxWidth: "100%",
                    width: "100%",
                  })}
                >
                  <Text
                    style={Object.assign(
                      {},
                      message?.user_id === Number(userId)
                        ? styles.chatUserText
                        : styles.chatOtherText,
                      styles.bubbleContainer,
                      {
                        justifyContent:
                          message?.user_id !== Number(userId)
                            ? "flex-start"
                            : "flex-end",
                      }
                    )}
                  >
                    {message?.message || ""}
                  </Text>
                </View>
              </View>
            ))}
        {loading ? <Loader /> : null}
        <Input
          style={styles.textInput}
          placeholder="Enter text"
          onChangeText={(txt: any) => {
            setText(txt);
          }}
          value={text}
        />
        <Button
          style={styles.button}
          onPress={() => {
            sendMessage(text).catch(console.error);
          }}
          disabled={!text}
        >
          <Text style={styles.buttonText}>Send</Text>
        </Button>
      </Card>
    </Layout>
  );
};

const styles = StyleSheet.create({
  bubbleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
  },
  button: {
    backgroundColor: primary,
    color: white,
    margin: 10,
    width: "100%",
  },
  buttonText: {
    color: white,
  },
  chatContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    width: "100%",
  },
  chatOtherText: {
    backgroundColor: primary,
    borderRadius: 10,
    color: white,
    padding: 10,
  },
  chatUserText: {
    backgroundColor: lightGrey,
    borderRadius: 10,
    color: black,
    padding: 10,
  },
  container: {
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    width: "100%",
  },
  icon: {
    fontSize: 32,
    height: 32,
    width: 32,
  },
  layout: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  textInput: {
    width: "100%",
  },
});

export default Chat;
