import * as React from "react";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { Link } from "@react-navigation/native";
import { Card, Layout, List, Text } from "@ui-kitten/components";
import axios from "axios";
import { Image, StyleSheet, Pressable, View } from "react-native";

import Loader from "app/components/loaders/Spinner";
import Scroll from "app/components/scroll/Scroll";
import { black, darkGrey, white } from "app/styles/theme";
import { fetchSubscription, HOSTNAME } from "app/utils/ajax";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";
import Options from "app/components/popups/Options";

const Messaging: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const [loading, setLoading] = React.useState(true);
  const [threads, setThreads] = React.useState([]);
  const [userId, setUserId] = React.useState(0);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const userId = await AsyncStorage.getItem("userId");
      setUserId(Number(userId));
      const token = await AsyncStorage.getItem("token");
      const { data } = await axios.get(`${HOSTNAME}/api/v1/message_threads`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setThreads(data?.data || []);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  React.useLayoutEffect(() => {
    // fetchSubscription().then((sub) => {
    //   if (!sub) {
    //     navigation.navigate("Membership Setup");
    //   }
    // });
    setRoute(route?.name);
  }, [setRoute, route?.name, navigation]);

  React.useEffect(() => {
    fetchMessages();
  }, []);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <View style={styles.row}>
        <View></View>
        <Text category="h1">Messages</Text>
        <Pressable onPress={fetchMessages}>
          <Text style={styles.icon}>⟳</Text>
        </Pressable>
      </View>
      {loading && <Loader />}
      {threads.length > 0 ? (
        <List
          refreshing={loading}
          onRefresh={fetchMessages}
          data={threads}
          style={Object.assign({}, styles.list)}
          contentContainerStyle={Object.assign({}, styles.list)}
          renderItem={({ item, index }: any) => (
            <View
              key={index}
              style={Object.assign({}, styles.card, {
                backgroundColor: themes === "light" ? white : darkGrey,
                borderColor: themes === "light" ? black : white,
              })}
            >
              <View style={styles.row}>
                <Link
                  to={{
                    screen: "Chat",
                    params: {
                      id: item?.id,
                      matchId:
                        item?.user_id === Number(userId)
                          ? item?.match?.user_id
                          : item?.user_id,
                    },
                  }}
                  key={index}
                  style={styles.row}
                >
                  <View style={Object.assign({}, styles.cardHeader)}>
                    <Image
                      style={Object.assign({}, styles.cardImage)}
                      source={{
                        uri:
                          item?.user_profile?.user_id === Number(userId)
                            ? item?.match_photos[0]?.photo_url
                            : item?.user_photos[0]?.photo_url,
                      }}
                    />
                  </View>
                  <View style={styles.cardSection}>
                    <Text category="h6" style={styles.cardName}>
                      {item?.user_profile?.user_id === Number(userId)
                        ? item?.match_profile?.first_name
                        : item?.user_profile?.first_name}
                    </Text>
                    <Text style={styles.cardMessage}>
                      {item?.messages?.length > 0
                        ? item?.messages[item?.messages?.length - 1]?.message
                        : "No messages yet"}
                    </Text>
                  </View>
                  <View style={styles.cardHeader}>
                    <Options
                      navigation={navigation}
                      route={route}
                      matchId={item?.user_profile?.user_id}
                    />
                  </View>
                </Link>
              </View>
            </View>
          )}
        />
      ) : (
        <Text>No messages yet</Text>
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  card: {
    alignItems: "center",
    borderRadius: 4,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: 100,
    display: "flex",
  },
  cardHeader: {
    height: "100%",
    width: "25%",
  },
  cardImage: {
    borderRadius: 50,
    height: 75,
    width: 75,
  },
  cardMessage: {
    flex: 1,
    marginLeft: 10,
    textAlign: "center",
  },
  cardName: {
    marginBottom: 5,
    marginLeft: 10,
    textAlign: "center",
  },
  cardSection: {
    alignItems: "flex-start",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    width: "50%",
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  container: {
    alignItems: "center",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    padding: 10,
    width: "100%",
  },
  icon: {
    fontSize: 32,
  },
  list: {
    width: "100%",
    flexDirection: "column",
    padding: 10,
    display: "flex",
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
  },
});

export default Messaging;
