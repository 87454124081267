import * as React from "react";

import { Layout, Text } from "@ui-kitten/components";
import {
  StyleSheet,
  Pressable,
  View,
  Platform,
  useWindowDimensions,
} from "react-native";
import video from "assets/video/iStock-858344024.mp4";
import VideoBackground from "app/components/video/VideoBackground";
import {
  darkGrey,
  maxHeight,
  maxWidth,
  primary,
  white,
} from "app/styles/theme";
import {
  AuthContext,
  ThemeContext,
  type ThemeContextProps,
  type AuthContextProps,
} from "app/utils/context";

const Home: React.FC<any> = ({ navigation, route }) => {
  const { setRoute }: AuthContextProps = React.useContext(
    AuthContext
  ) as AuthContextProps;
  const { themes } = React.useContext(ThemeContext) as ThemeContextProps;

  const layout = useWindowDimensions();
  const width = layout.width > maxWidth ? maxWidth : layout.width;
  const height = layout.height > maxHeight ? maxHeight : layout.height;

  const title = { fontSize: width * 0.075 };
  const subTitle = { fontSize: width * 0.045 };
  const buttonText = {
    fontSize: width * 0.05,
  };
  const subContainer = {
    width: width * 0.8,
    height: height * 0.8,
  };

  React.useLayoutEffect(() => {
    setRoute(route?.name);
  }, [setRoute, route?.name]);

  return (
    <Layout
      style={Object.assign({}, styles.container, {
        backgroundColor: themes === "light" ? white : darkGrey,
      })}
    >
      <VideoBackground style={styles.videoBackground} />
      <View style={Object.assign({}, styles.subContainer, subContainer)}>
        <View style={styles.titleContainer}>
          <Text style={Object.assign({}, title, styles.title)}>
            Megachurch Online
          </Text>
        </View>
        <View style={styles.imageContainer}></View>
        <View style={styles.subTitleContainer}>
          <Text style={Object.assign({}, subTitle, styles.subTitle)}>
            Finding love has never been easier
          </Text>
        </View>
        <Pressable
          style={Object.assign({}, styles.buttonContainer, {
            color: themes === "light" ? white : darkGrey,
          })}
          onPress={() => {
            setRoute("Sign Up");
            navigation.navigate("Sign Up");
          }}
        >
          <View>
            <Text
              style={Object.assign(
                {},
                buttonText,
                Object.assign({}, styles.buttonText, {
                  color: themes === "light" ? white : darkGrey,
                })
              )}
            >
              Get Started
            </Text>
          </View>
        </Pressable>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: "center",
    backgroundColor: primary,
    borderRadius: 50,
    display: "flex",
    height: Platform.OS === "web" ? "5%" : "7.5%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: Platform.OS === "web" ? "80%" : "100%",
  },
  buttonText: {
    alignItems: "center",
    backgroundColor: primary,
    display: "flex",
    fontSize: 24,
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    height: "100%",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  image: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
  imageContainer: {
    alignItems: "center",
    display: "flex",
    height: "60%",
    justifyContent: "center",
    width: "100%",
  },
  subContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-around",
    margin: 0,
    padding: 0,
    width: "100%",
  },
  subTitle: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    justifyContent: "center",
  },
  subTitleContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    alignItems: "center",
    color: primary,
    display: "flex",
    fontFamily: "Futura",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    textAlign: "center",
    width: "100%",
  },
  titleContainer: {
    alignItems: "center",
    display: "flex",
    height: Platform.OS === "web" ? "5%" : "auto",
    justifyContent: "center",
    width: "100%",
  },
  videoBackground: {
    minHeight: "100%",
    minWidth: "100%",
    position: "absolute",
  },
});

export default Home;
